import React from 'react';
import Layout from '../Layout';
import "./ceomessage.scss";
import image from "../../images/manisha-khemlani.jpg"
import sign from "../../images/mk-sign.png";

const CEOMessage = () => {
    return (
        <Layout>
            <div className='ceo-msg'>
                <h1>Message from the Global Head</h1>

                <div className="divider"></div>
                <p>The Chinmaya Mission’s seva projects and participation at the Tata Mumbai Marathon are geared towards creating exponential, sustainable social impact.
                    <br /><br />
                    Swami Swaroopananda Ji, the Global Head of the Chinmaya Mission expresses his gratitude to all those who have played a part in fostering growth and bringing Pujya Gurudev’s vision to life.
                </p>
                <div className='video-container'>
                    <iframe className='video' src="https://www.youtube.com/embed/mT1zlhIRoG8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <p style={{ textAlign: "center" }}>Click on the link below to add your support and share the fundraiser with your network.<br />
                    <br />
                    <a className="btn" href="https://www.unitedwaymumbai.org/fundraiser/17346"> Donate Now</a>
                </p>
            </div>
        </Layout>
    );
}

export default CEOMessage;
